<template>
  <LargeLayout>
    <div
      class="card d-flex flex-column gap-3 border-0"
      v-for="property in properties"
      :key="property._id"
    >
      <Card
        :property="property"
        :id="property._id"
        :title="property.title"
        :image="property.images[0]"
        :plan="property.plan"
        :address="property.address"
        :cost="property.cost"
        :utility_cost="property.utility_costs"
        :wifi_cost="property.wifi_cost"
        :area="property.square"
        :bedroom="property.bedroom"
        :bathroom="property.bathroom"
        :neighborhood="property.condition[0]"
      />
    </div>

    <b-pagination
      v-if="properties.length > 0"
      v-model="page"
      :total-rows="count"
      :per-page="size"
      @change="handlePageChange"
      aria-controls="history-list"
      class="mt-4"
      align="center"
    ></b-pagination>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import Card from "../../components/utils/Card.vue";
import axiosConfig from "../../libs/axiosConfig";
export default {
  name: "FilterResult",
  components: { LargeLayout, Card },
  data() {
    return {
      page: 1,
      size: 8,
      count: 0,
      properties: [],
      filterData: {},
      searchQuery: {},
    };
  },
  created() {
    this.fetch_data();
  },
  computed: {
    query() {
      return (
        this.$route.query.plan +
        this.$route.query.option +
        this.$route.query.type
      );
    },
  },
  methods: {
    fetch_data() {
      this.searchQuery = {
        type: [this.$route.query.type],
        option: [this.$route.query.option],
        plan: [
          "studio",
          "1ldk",
          "2ldk",
          "3ldk",
          "4ldk",
          "1dk",
          "1k",
          "2k",
          "2dk"
        ],
      };
      if (this.$route.query.option) {
        axiosConfig
          .post("/filter?page=" + this.page + "&size="+this.size, this.searchQuery)
          .then((response) => {
            this.filterData = {
              option: [this.$route.query.option],
              type: [this.$route.query.type],
              plan: [
                "studio",
                "1ldk",
                "2ldk",
                "3ldk",
                "4ldk",
                "1dk",
                "1k",
                "2k",
                "2dk"
              ],
            };
            this.properties = response.data.properties;
            this.count = response.data.totalItems;
          });
      } else {
        this.searchQuery = {
          option: ["for_rent", "for_sale"],
          type: ["apartment", "house", "office"],
          plan: [this.$route.query.plan],
        };
        axiosConfig
          .post("/filter?page=" + this.page+ "&size="+this.size, this.searchQuery)
          .then((response) => {
            // console.log(response)
            this.properties = response.data.properties;
            this.count = response.data.totalItems;
            this.filterData = {
              option: ["for_rent", "for_sale"],
              type: ["apartment", "house", "office"],
              plan: [this.$route.query.plan],
            };
          })
          .catch((e)=>{
           console.log(e);
          })
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      });
      axiosConfig
        .post("/filter?page=" + this.page + "&size="+this.size, this.searchQuery)
        .then((response) => {
          this.properties = response.data.properties;
          this.count = response.data.totalItems;
        });
    },
  },

  watch: {
    query() {
      this.fetch_data();
    },
  },
};
</script>

<style></style>
